<template>
  <div>
    <el-card shadow="never">
      <div class="flex ju-be">
        <div class="flex">
          <h2 class="size-16 color-8">小程序配置</h2>
          <p class="ml-36 size-14 co-66">
            注：由于微信小程序机制，更改内容后需要提交小程序审核，发布后才可生效。
          </p>
        </div>
        <div class="flex">
          <el-button type="primary" @click="dialogVisibleAdd = true, selectAddValue = ''">添加导航</el-button>
          <el-button type="primary" @click="previewDialogVisible = true">预览效果</el-button>
          <el-button type="primary" @click="saveTab">保存设置</el-button>
        </div>
      </div>
      <div class="xian mtb-20"></div>
      <el-table :data="noteList" style="width: 100%" :row-class-name="tableRowClassName">
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column align="center" prop="date" label="未选中状态" width="180">
          <div slot-scope="scope">
            <el-upload accept="image/jpeg,image/png" class="addTabIcon" :action="uploadUrl" :show-file-list="false"
              :on-success="dynamicPicSuccess.bind(null, {
                index: scope.row.index,
                data: scope.row,
              })
                " :before-upload="beforeUploadImageDynamicPic">
              <img v-if="scope.row.iconPath" :src="scope.row.iconPath" class="avatar dynamic" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-table-column>
        <el-table-column align="center" prop="name" label="选中状态" width="180">
          <div slot-scope="scope">
            <el-upload accept="image/jpeg,image/png" class="addTabIcon" :action="uploadUrl" :show-file-list="false"
              :on-success="dynamicPicSuccessA.bind(null, {
                index: scope.row.index,
                data: scope.row,
              })
                " :before-upload="beforeUploadImageDynamicPic">
              <img v-if="scope.row.selectedIconPath" :src="scope.row.selectedIconPath" class="avatar dynamic" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-table-column>
        <el-table-column align="center" prop="text" label="模块名称">
          <div slot-scope="scope">
            <el-input type="text" maxlength="4" v-model="scope.row.text" show-word-limit style="width: 200px">
            </el-input>
          </div>
        </el-table-column>
        <el-table-column align="center" prop="subheading" label="模块页面">
          <div slot-scope="scope">
            <el-select v-model="scope.row.subheading" style="width: 200px" placeholder="请选择"
              @change="select_tap($event, scope.row.index)">
              <el-option v-for="v in optionList" :key="v.text" :disabled="v.disabled" :label="v.text" :value="v.text" />
            </el-select>
          </div>
        </el-table-column>
        <el-table-column sortable align="center" prop="address" label="排序">
          <div slot-scope="scope">
            <el-input type="text" v-model="scope.row.sortNum" placeholder="数值越大，顺序越靠前" show-word-limit
              style="width: 200px" @blur="sortSubmit">
            </el-input>
          </div>
        </el-table-column>
        <el-table-column align="center" prop="address" label="开启页面">
          <div slot-scope="scope">
            <el-switch v-model="scope.row.openPage" @change="switchChange($event, scope.row.index)">
            </el-switch>
          </div>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <div slot-scope="scope">
            <el-button type="text" @click="deleteTab(scope.row)">删除</el-button>
          </div>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- :close-on-click-modal="false" -->
    <!-- 添加 -->
    <el-dialog title="添加导航" :visible.sync="dialogVisibleAdd" width="50%" :modal-append-to-body="false"
      :destroy-on-close="true" center>
      <el-form label-width="78px">
        <el-form-item label="模块页面">
          <el-select style="width: 100%" v-model="selectAddValue" placeholder="请选择模块页面">
            <el-option v-for="v in optionList" :key="v.text" :disabled="v.disabled" :label="v.text" :value="v.text" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 效果预览 -->
    <el-dialog title="" :visible.sync="previewDialogVisible" :modal-append-to-body="false" :lock-scroll="false"
      width="40%" class="dialog-box" top="50px">
      <div class="bg-box">
        <div style="width: 100%; height: 650px; overflow: hidden">
          <img style="width: 100%" src="../../../assets/images/content1.png" />
        </div>
        <div class="d-flex flex1 bgfff justify-content-between">
          <div style="width: 52px; height: 100%" class="flex-c-c flex-column" v-for="(item, index) in tabList"
            :key="index">
            <img :src="item.iconPath" class="w40 h40" />
            <p class="fs6">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { saveTabMenu, queryTabMenu } from "@/api/wechat";
import { getDataTimeSec } from "@/utils";
import { json } from "body-parser";
export default {
  name: "appointmentList",
  data() {
    return {
      selectAddValue: "",
      dialogVisibleAdd: false,
      previewDialogVisible: false,
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      noteList: [],
      tabList: [
        {
          text: "名片",
          subheading: "名片",
          pagePath: "pages/index/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/card.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/card_select.png",
        },
        {
          text: "媒体",
          subheading: "媒体",
          pagePath: "pages/media/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/video.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/video_select.png",
        },
        {
          text: "商城",
          subheading: "商城",
          pagePath: "pages/Products/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/prod_gray.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/prod_select.png",
        },
        {
          text: "动态",
          subheading: "动态",
          pagePath: "pages/Dynamic/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/browser.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/browser_select.png",
        },
        {
          text: "官网",
          subheading: "官网",
          pagePath: "pages/WebSite/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/computer.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/computer_select.png",
        },
      ],
      optionList: [
        {
          text: "名片",
          subheading: "名片",
          disabled: true,
          pagePath: "pages/index/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/card.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/card_select.png",
        },
        {
          text: "人脉圈",
          subheading: "人脉圈",
          disabled: true,
          pagePath: "pages/contactsMarket/index/main",
          iconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/connections.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/connections_select.png",
        },
        {
          text: "探索",
          subheading: "探索",
          type: "video",
          disabled: true,
          pagePath: "pages/explore/main",
          iconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/lightbulb-flash-line.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/lightbulb-flash-fill.png",
        },
        {
          text: "媒体",
          subheading: "媒体",
          type: "video",
          disabled: true,
          pagePath: "pages/media/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/video.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/video_select.png",
        },
        {
          text: "官网",
          subheading: "官网",
          disabled: true,
          pagePath: "pages/WebSite/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/computer.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/computer_select.png",
        },
        {
          text: "解决方案",
          subheading: "解决方案",
          disabled: true,
          pagePath: "pages/solution/mian",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/solution.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/solution_select.png",
        },
        {
          text: "产品手册",
          subheading: "产品手册",
          disabled: true,
          pagePath: "pages/articleProduct/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/product.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/product_select.png",
        },
        {
          text: "动态",
          subheading: "动态",
          disabled: true,
          pagePath: "pages/Dynamic/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/browser.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/browser_select.png",
        },
        {
          text: "商城",
          subheading: "商城",
          disabled: true,
          pagePath: "pages/Products/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/prod_gray.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/prod_select.png",
        },
        {
          text: "休息一下",
          subheading: "休息一下",
          disabled: true,
          pagePath: "pages/index/restH5",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/prod_gray.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/prod_select.png",
        },
        {
          text: "增值服务",
          subheading: "增值服务",
          disabled: false,
          pagePath: "pages/services/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/member.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/member_select.png",
        },
        {
          text: "AI模型",
          subheading: "AI模型",
          disabled: false,
          pagePath: "pages/aiBlend/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/ai_model.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/ai_model_select.png",
        },
        {
          text: "色卡本",
          subheading: "色卡本",
          disabled: true,
          pagePath: "pages/productClassify/main",
          iconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/productClassify.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/productClassify_select.png",
        },
        {
          text: "资料库",
          subheading: "资料库",
          disabled: true,
          pagePath: "pages/dataBase/main",
          iconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/productClassify.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/productClassify_select.png",
        },
        {
          text: "个人中心",
          subheading: "个人中心",
          disabled: true,
          pagePath: "pages/mine/main",
          iconPath: this.$store.state.ossUrl + "jquen_photos/tabbarIcon/my.png",
          selectedIconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/my_select.png",
        },
        {
          text: "购物车",
          subheading: "购物车",
          disabled: true,
          pagePath: "pages/cart/main",
          iconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/shoppingCart.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/shoppingCart_select.png",
        },
        {
          text: "电子样册",
          subheading: "电子样册",
          disabled: true,
          pagePath: "pages/electronBook/main",
          iconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/product.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/product_select.png",
        },
        {
          text: "高定商城",
          subheading: "高定商城",
          disabled: true,
          pagePath: "pages/3DProduct/main",
          iconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/icon_base.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/icon_base_select.png",
        },
      ],
      isUpdate: false,
    };
  },
  watch: {
    // tabList(val,old){
    //
    // }
    noteList: {
      handler(val, old) {
        console.log(val, "val");
        this.optionList.forEach((v) => {
          v.disabled = false;
          val.forEach((item, index) => {
            if (item.pagePath == v.pagePath) {
              v.disabled = true;
            }
          });
        });
      },
      deep: true,
      immediate: true,
    },
  },
  filters: {
    filtration(key, tabList) {
      tabList.forEach((item) => {
        if (item.text == key) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.queryTabList();
  },
  methods: {
    switchChange(val, i) {
      let a = 0;
      this.noteList.forEach(el => {
        if (el.openPage) {
          a++
        }
      })
      // if (a < 2) {
      //   this.noteList[i].openPage = true;
      //   this.$message.warning('小程序最少需要两个导航栏！');
      // }
      if (a > 5) {
        this.noteList[i].openPage = false;
        this.$message.warning('小程序最多只能五个导航栏！');
      }
      console.log(a, '开启页面-------');
    },
    sortSubmit() {
      let arr = JSON.parse(JSON.stringify(this.noteList))
      function fuch(a, b) {
        return b.sortNum - a.sortNum;
      }
      this.noteList = arr.sort(fuch)
      // console.log(arr.sort(fuch),'arr----------');
    },
    submitAdd() {
      if (this.selectAddValue == "") {
        this.$message.warning("请选择要添加得模块页面！");
        return;
      }
      if (this.noteList.length == 0) {
        this.noteList.push({
          text: "名片",
          iconPath: this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/card.png",
          selectedIconPath: this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/card_select.png",
          subheading: "名片",
          pagePath: "pages/index/main",
        });
      } else {
        this.optionList.forEach((el) => {
          if (el.text == this.selectAddValue) {
            this.noteList.push({
              text: el.text,
              iconPath: el.iconPath,
              subheading: el.subheading,
              selectedIconPath: el.selectedIconPath,
              pagePath: el.pagePath,
              openPage: false,
              sortNum: 0
            });
          }
        });
      }
      this.$message.success("添加成功！");
      this.dialogVisibleAdd = false;
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
    //请求表格数据
    queryTabList() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
      };
      let that = this;
      queryTabMenu(data)
        .then((res) => {
          if (res.data && res.data.note) {
            that.tabList = JSON.parse(res.data.note);
            console.log(that.tabList, 'this.tabList----------------');
            that.noteList = JSON.parse(res.data.noteList);
            console.log(that.noteList, 'this.noteList----------------');
          }
        })
        .catch((err) => {
          this.loading = false;
          this.tabList = this.tabList;
        });
    },
    beforeUploadImageDynamicPic(file) {
      var _this = this;
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (["image/jpeg", "image/png"].indexOf(file.type) == -1) {
        _this.$message.error("请上传正确的图片格式");
        return false;
      }
      if (!isLt10M) {
        _this.$message.error("上传图片大小不能超过10MB哦!");
        return false;
      }
    },

    //动态图上传成功
    dynamicPicSuccess(obj, res, file) {
      var index = obj.index;
      this.$set(this.noteList[index], "iconPath", this.ossUrl + "/" + res.data);
    },
    select_tap(key, index) {
      let than = this;
      console.log(key);
      this.optionList.forEach((v) => {
        if (v.text == key) {
          than.noteList[index].pagePath = v.pagePath;
          than.noteList[index].iconPath = v.iconPath;
          than.noteList[index].subheading = v.subheading;
          than.noteList[index].selectedIconPath = v.selectedIconPath;
          than.noteList[index].type = v.type;
          than.noteList[index].text = v.text;
          than.noteList[index].openPage = false;
        }
      });
    },

    //动态图上传成功
    dynamicPicSuccessA(obj, res, file) {
      var index = obj.index;
      this.$set(
        this.noteList[index],
        "selectedIconPath",
        this.ossUrl + "/" + res.data
      );
    },
    deleteTab(item) {
      if (item.pagePath == 'pages/index/main') {
        this.$message.warning("不能删除名片页！");
        return false;
      }
      let than = this;
      if (than.noteList.length == 2) {
        return than.$message.error("小程序最少需要两个导航栏！");
      }
      than.noteList.splice(item.index, 1);
      // than.noteList.forEach((v, k) => {
      //   if (v.text == item.text) {
      //     than.noteList.splice(k, 1);
      //     console.log(than.noteList, "than.tabList");
      //   }
      // });
    },
    addTab() {
      // if (this.tabList.length >= 5) {
      //   return this.$message.error("小程序最多五个导航栏！");
      // }
      if (this.noteList.length == 0) {
        this.noteList.push({
          text: "名片",
          iconPath: "static/tabs/card.png",
          selectedIconPath: "static/tabs/card_select.png",
          subheading: "名片",
          pagePath: "pages/index/main",
        });
      } else {
        this.noteList.push({
          text: "",
          iconPath: "",
          subheading: "",
          selectedIconPath: "",
        });
      }
    },
    //保存菜单
    saveTab() {
      this.tabList = [];
      this.noteList.forEach((el) => {
        if (el.openPage) {
          this.tabList.push({
            text: el.text,
            subheading: el.subheading,
            iconPath: el.iconPath,
            selectedIconPath: el.selectedIconPath,
            pagePath: el.pagePath,
          });
        }
      });
      console.log(this.tabList, 'this.tabList8888888888');
      let content = [];
      for (var k in this.tabList) {
        let v = this.tabList[k];
        if (!v.iconPath || !v.pagePath || !v.selectedIconPath || !v.text) {
          return this.$message.error("导航栏信息不完整，请确认！");
        }
        switch (v.pagePath) {
          case "pages/index/main":
            content.push({
              text: v.text,
              subheading: "名片",
              iconPath: "static/tabs/card.png",
              selectedIconPath: "static/tabs/card_select.png",
              pagePath: "pages/index/main",
            });
            break;
          case "pages/media/main":
            content.push({
              text: v.text,
              subheading: "媒体",
              iconPath: "static/tabs/video.png",
              selectedIconPath: "static/tabs/video_select.png",
              pagePath: "pages/media/main",
            });
            break;
          case "pages/explore/main":
            content.push({
              text: v.text,
              subheading: "探索",
              iconPath: "static/tabs/lightbulb-flash-line.png",
              selectedIconPath: "static/tabs/lightbulb-flash-fill.png",
              pagePath: "pages/explore/main",
            });
            break;
          case "pages/Products/main":
            content.push({
              text: v.text,
              subheading: "商城",
              iconPath: "static/tabs/prod_gray.png",
              selectedIconPath: "static/tabs/prod_select.png",
              pagePath: "pages/Products/main",
            });
            break;
          case "pages/Dynamic/main":
            content.push({
              text: v.text,
              subheading: "动态",
              iconPath: "static/tabs/browser.png",
              selectedIconPath: "static/tabs/browser_select.png",
              pagePath: "pages/Dynamic/main",
            });
            break;
          case "pages/WebSite/main":
            content.push({
              text: v.text,
              subheading: "官网",
              iconPath: "static/tabs/computer.png",
              selectedIconPath: "static/tabs/computer_select.png",
              pagePath: "pages/WebSite/main",
            });
            break;
          case "pages/services/main":
            content.push({
              text: v.text,
              subheading: "增值服务",
              iconPath: "static/tabs/member.png",
              selectedIconPath: "static/tabs/member_select.png",
              pagePath: "pages/services/main",
            });
            break;
          case "pages/contactsMarket/index/main":
            content.push({
              text: v.text,
              subheading: "人脉圈",
              iconPath: "static/tabs/connections.png",
              selectedIconPath: "static/tabs/connections_select.png",
              pagePath: "pages/contactsMarket/index/main",
            });
            break;
          case "pages/articleProduct/main":
            content.push({
              text: v.text,
              subheading: "产品手册",
              iconPath: "static/tabs/product.png",
              selectedIconPath: "static/tabs/product_select.png",
              pagePath: "pages/articleProduct/main",
            });
            break;
          case "pages/index/restH5":
            content.push({
              text: v.text,
              subheading: "休息一下",
              iconPath: "static/tabs/member.png",
              selectedIconPath: "static/tabs/member_select.png",
              pagePath: "pages/index/restH5",
            });
            break;
          case "pages/aiBlend/main":
            content.push({
              text: v.text,
              subheading: "AI模型",
              iconPath: "static/tabs/ai_model.png",
              selectedIconPath: "static/tabs/ai_model_select.png",
              pagePath: "pages/aiBlend/main",
            });
            break;
          case "pages/solution/mian":
            content.push({
              text: v.text,
              subheading: "解决方案",
              iconPath: "static/tabs/solution.png",
              selectedIconPath: "static/tabs/solution_select.png",
              pagePath: "pages/solution/mian",
            });
            break;
          case "pages/mine/main":
            content.push({
              text: v.text,
              subheading: "个人中心",
              iconPath: "static/tabs/my.png",
              selectedIconPath: "static/tabs/my_select.png",
              pagePath: "pages/mine/main",
            });
            break;
          case "pages/productClassify/main":
            content.push({
              text: v.text,
              subheading: "色卡本",
              iconPath: "static/tabs/productClassify.png",
              selectedIconPath: "static/tabs/productClassify_select.png",
              pagePath: "pages/productClassify/main",
            });
            break;
            case "pages/productClassify/main":
            content.push({
              text: v.text,
              subheading: "资料库",
              iconPath: "static/tabs/productClassify.png",
              selectedIconPath: "static/tabs/productClassify_select.png",
              pagePath: "pages/dataBase/main",
            });
            break;
          case "pages/cart/main":
            content.push({
              text: v.text,
              subheading: "购物车",
              iconPath: "static/tabs/shoppingCart.png",
              selectedIconPath: "static/tabs/shoppingCart_select.png",
              pagePath: "pages/cart/main",
            });
            break;
          case "pages/electronBook/main":
            content.push({
              text: v.text,
              subheading: "电子样册",
              iconPath: "static/tabs/product.png",
              selectedIconPath: "static/tabs/product_select.png",
              pagePath: "pages/electronBook/main",
            });
            break;
          case "pages/3DProduct/main":
            content.push({
              text: v.text,
              subheading: "高定商城",
              iconPath: "static/tabs/icon_base.png",
              selectedIconPath: "static/tabs/icon_base_select.png",
              pagePath: "pages/3DProduct/main",
            });
            break;
          default:
            break;
        }
      }
      console.log(this.tabList, '提交this.tabList----------------');
      console.log(content, '提交content----------------');
      let data = {
        noteList: JSON.stringify(this.noteList),
        note: JSON.stringify(this.tabList),
        content: JSON.stringify(content),
      };
      console.log(data, '提交data-------------');
      saveTabMenu(data).then((res) => {
        this.$message.success(res.message);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-box {
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);

  ::v-deep.el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep.el-dialog {
    box-shadow: none;
    background: #ffffff;
  }

  .bg-box {
    padding: 50px 30px 0px 30px;
    background-image: url("../../../assets/images/iphonex.png");
    width: 400px;
    height: 800px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.appointmentList-view {
  display: flex;
  background: #ffffff;
  padding: 24px;
}

.phoneClass {
  padding: 50px 30px 0px 30px;
  background-image: url("../../../assets/images/iphonex.png");
  width: 400px;
  height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  img {
    width: 100%;
  }
}

.phoneClass img {
  width: 100%;
}

/deep/.addTabIcon .el-upload--text {
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 20px;
}

/deep/ .addTabIcon .el-upload-list__item {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.bradius4 {
  border-radius: 4px;
}

.textc {
  text-align: center;
}

.bced {
  border: 1px solid #ced4da;
}

.ohidden {
  overflow: hidden;
}

.icon-plus {
  position: relative;
  cursor: pointer;
  margin-right: 20px;
}

.icon-plus:before,
.icon-plus:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(206, 212, 218);
}

.icon-plus:before {
  height: 60%;
  width: 1px;
}

.icon-plus:after {
  height: 1px;
  width: 60%;
}

.opacity0 {
  z-index: 1;
  opacity: 0;
}

.position-relative {
  position: relative;
}

.goods-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.avatar {
  width: 100%;
  height: 100%;
}
</style>